import React from 'react';
import { useTranslation } from 'react-i18next';
import { TwitterIcon, TwitterShareButton as TwitterButton } from 'react-share';

export const TwitterShareButton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <TwitterButton
      className={'onm-social twitter'}
      url="https://www.outbreaksnearme.org/"
      hashtags={[
        t('Footer.hashtags.PublicHealthTrack'),
        t('Footer.hashtags.COVID19'),
        t('Footer.hashtags.COVID19Pandemic'),
      ]}
      title={t('Footer.twitterShareMessage')}
    >
      <TwitterIcon size={32} />
      Twitter
    </TwitterButton>
  );
};
