import React from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookIcon, FacebookShareButton as FBButton } from 'react-share';

export const FacebookShareButton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FBButton
      className={'onm-social facebook'}
      quote={t('Footer.facebookShareQuote')}
      url="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%outbreaksnearme.org&amp;layout=button_count&amp;size=large&amp;appId=1639803052928366&amp;width=88&amp;height=28"
    >
      <FacebookIcon size={32} />
      Facebook
    </FBButton>
  );
};
