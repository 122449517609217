import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';
import { Grid, Typography } from '@material-ui/core';

export const HeadingMessage = styled(Typography).attrs({ variant: 'h3' })`
  margin-bottom: ${props => props.theme.spacing(3)}px;
  font-weight: normal;
  text-align: left;
  line-height: 140%;
  font-size: 36px;
`;

export const BodyMessage = styled(Typography).attrs({ variant: 'body1' })`
  text-align: left;
  margin-bottom: ${props => props.theme.spacing(5)}px;
`;

export const ActionConfirmationContainer = styled(Grid)`
  margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    margin-right: ${({ theme }) => `${theme.spacing(1)}px`};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Box = styled(Grid).attrs({
  spacing: 10,
  container: true,
  direction: 'column',
  justify: 'space-between',
})`
  ${breakpoint.md`
    padding-left: ${props => `${props.theme.spacing(5)}px`};
    padding-right: ${props => `${props.theme.spacing(5)}px`};
  `}
`;

export const SocialButtons = styled(Grid)`
  .onm-social {
    color: ${props => props.theme.palette.common.white} !important;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 4px !important;

    &.facebook {
      background-color: ${props =>
        props.theme.palette.background.blue} !important;
    }

    &.twitter {
      background-color: ${props =>
        props.theme.palette.background.cerulean} !important;
    }

    rect {
      fill: transparent !important;
    }
  }
`;
