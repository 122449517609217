import React from 'react';
import Hero from '~/components/Hero';
import { Grid } from '@material-ui/core';
import { ShareButtons } from './ShareButtons';
import { useTranslation } from 'react-i18next';
import { BonusQuestions } from '~/components/forms/Well/BonusQuestion';
import * as Styled from './styles';
import { PageProps } from 'gatsby';

interface Props extends PageProps {
  location: Location & {
    state: {
      username?: string;
      isWellForm?: boolean;
      bonusQuestionsAsked?: boolean;
    };
  };
}

export const ThankYouPage: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const username = location?.state?.username;
  const isWellForm = location?.state?.isWellForm;
  const bonusQuestionsAsked = location?.state?.bonusQuestionsAsked;
  let headingMessage = t('ThankYou.heading');
  headingMessage += username ? `, ${username}!` : '!';

  if (isWellForm && !bonusQuestionsAsked) {
    return <BonusQuestions />;
  }

  return (
    <Hero>
      <Styled.Box>
        <Grid item xs>
          <Styled.HeadingMessage>{headingMessage}</Styled.HeadingMessage>
          <Styled.BodyMessage>{t('ThankYou.body')}</Styled.BodyMessage>
          <ShareButtons />
        </Grid>
      </Styled.Box>
    </Hero>
  );
};
