import React from 'react';
import { navigate } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import * as FormStyles from '~/components/forms/styles';
import { useLanguageUrl } from '~/state/hooks/useLanguageUrl';
import { TwitterShareButton, FacebookShareButton } from '~/components/buttons';
import * as Styled from './styles';

export const ShareButtons: React.FC = () => {
  const { t } = useTranslation();
  const baseUrl = useLanguageUrl();

  const goToMap = () => {
    navigate(`${baseUrl}`, { state: { goToMap: true } });
  };

  return (
    <Styled.SocialButtons container spacing={2}>
      <Grid item xs={12} md={6}>
        <FacebookShareButton />
      </Grid>
      <Grid item xs={12} md={6}>
        <TwitterShareButton />
      </Grid>
      <Grid item xs={12}>
        <FormStyles.RegularCaseButton fullWidth onClick={goToMap}>
          {t('ThankYou.skipShare')}
        </FormStyles.RegularCaseButton>
      </Grid>
    </Styled.SocialButtons>
  );
};
