import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { ThankYouPage } from '~/components/ThankYou';

const ThankYou: React.FC<PageProps> = props => (
  <>
    <SEO pageContext={props.pageContext} title="ThankYou.pageTitle" />
    <ThankYouPage {...props} />
  </>
);

export default ThankYou;
